import Form from 'react-bootstrap/Form';
import React, { useState, useEffect } from 'react';
import jsonData from "../jsons/adoption_breadth_features.json"
import Button from 'react-bootstrap/Button';
import { get_adoption_breadth_inputs, send_adoption_breadth_inputs } from '../services/analysis';


export default function AdoptionBreadthFeatureForm({ changeLock, setChangeLock, userCanEdit, accountName, getUserEmail, authState, setSpinner }) {

    const [formValues, setFormValues] = useState({});
    const [buttonText, setButtonText] = useState('Edit');
    const [buttonVariant, setButtonVariant] = useState('primary');
    const [sending, setSending] = useState(false);
    const [updatedAt, setUpdatedAt] = useState('');
    // Initialize state based on JSON data or predefined keys
    useEffect(() => {
        const initialFormValues = {};

        jsonData.top_section.forEach(item => {
            const key = Object.keys(item)[0];
            initialFormValues[key] = '';
        });

        Object.keys(jsonData.features).forEach(category => {
            jsonData.features[category].forEach(item => {
                if (item.features) {
                    item.features.forEach(feature => {
                        const key = Object.keys(feature)[0];
                        initialFormValues[key] = {
                            reason_status: '',
                            issues_selector: '',
                            date_selector: '',
                            competitor_selector: '',
                            reason_selector: '',
                            comments_text_box: ''
                        };
                    });
                }
            });
        });

        setFormValues(initialFormValues);
    }, []);


    useEffect(() => {
        if (accountName.length > 1) {
            async function update_form() {
                setSpinner(true)
                let response = await get_adoption_breadth_inputs(accountName, authState)
                updateFormFieldsFromDatabase(response)
                scrollToTop()
                setSpinner(false)

            }
            update_form()
            
        }

    }, [accountName]);


    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // for smooth scrolling
        });
    };


    // Handle input change
    const handleInputChange = (key, field, value) => {
        if (typeof formValues[key] === 'object') {
            setFormValues(prevValues => ({
                ...prevValues,
                [key]: {
                    ...prevValues[key],
                    [field]: value
                }
            }));
        } else {
            setFormValues(prevValues => ({
                ...prevValues,
                [key]: value
            }));
        }
    };


    const handleEditClick = () => {
        if (changeLock) {
            setChangeLock(false);
            setButtonText('Discard');
            setButtonVariant('danger');
        } else {
            setChangeLock(true);
            setButtonText('Edit');
            setButtonVariant('primary');
        }
    };

    const handleSubmit = async () => {
        setSending(true)
        let userEmail = getUserEmail()
        let response = await send_adoption_breadth_inputs(accountName, formValues, authState, userEmail)
        if (response == true) {
            alert("Data Inserted")
            handleEditClick()
            //handleClearFields()
        }
        setSending(false)
    }

    const handleClearFields = () => {
        const clearedFormValues = {};

        jsonData.top_section.forEach(item => {
            const key = Object.keys(item)[0];
            clearedFormValues[key] = '';
        });

        Object.keys(jsonData.features).forEach(category => {
            jsonData.features[category].forEach(item => {
                if (item.features) {
                    item.features.forEach(feature => {
                        const key = Object.keys(feature)[0];
                        clearedFormValues[key] = {
                            reason_status: '',
                            issues_selector: '',
                            date_selector: '',
                            competitor_selector: '',
                            reason_selector: '',
                            comments_text_box: ''
                        };
                    });
                }
            });
        });

        setFormValues(clearedFormValues);
    };

    const updateFormFieldsFromDatabase = (databaseData) => {
        try {
            if (databaseData.length > 0) {

                const { ENTRIES, UPDATED_AT } = databaseData[0];
                const parsedEntries = JSON.parse(ENTRIES);

                // Ensure the structure matches the expected formValues structure
                const updatedFormValues = {};
                setUpdatedAt(UPDATED_AT)
                jsonData.top_section.forEach(item => {
                    const key = Object.keys(item)[0];
                    updatedFormValues[key] = parsedEntries[key] || '';
                });

                Object.keys(jsonData.features).forEach(category => {
                    jsonData.features[category].forEach(item => {
                        if (item.features) {
                            item.features.forEach(feature => {
                                const key = Object.keys(feature)[0];
                                updatedFormValues[key] = parsedEntries[key] || {
                                    reason_status: '',
                                    issues_selector: '',
                                    date_selector: '',
                                    competitor_selector: '',
                                    reason_selector: '',
                                    comments_text_box: ''
                                };
                            });
                        }
                    });
                });

                setFormValues(updatedFormValues);

            }
            else {
                handleClearFields()
            }

        } catch (error) {
            console.error('Failed to parse database JSON:', error);
        }
    };

    return (
        <div>
            <div className="top_form">
                {jsonData.top_section.map(item => {
                    const key = Object.keys(item)[0];
                    const height = item[key];
                    return (
                        <div className='form-top'>
                            <div key={key} style={{ height }}>
                                <Form.Group>
                                    <Form.Label>{key.replace('_', ' ')}</Form.Label>
                                    <Form.Control
                                        className='comments_text_box'
                                        as="textarea"
                                        rows={3}
                                        value={formValues[key] || ''}
                                        onChange={e => handleInputChange(key, null, e.target.value)}
                                        disabled={changeLock}
                                    />
                                </Form.Group>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className='updated_at'>Last CSM update: {updatedAt.slice(0, 10)}</div>
            <div className="features_container" style={{ position: 'absolute', top: jsonData.feature_start_absolute_position }}>
                {Object.keys(jsonData.features).map(category => {
                    const section = jsonData.features[category];
                    const sectionTitleHeight = section.find(item => item.section_title_height).section_title_height;
                    const gap = section.find(item => item.gap).gap;

                    return (
                        <div key={category} style={{ marginTop: sectionTitleHeight }}>

                            {section.find(item => item.features).features.map((feature, index) => {
                                const key = Object.keys(feature)[0];
                                const height = feature[key];
                                return (
                                    <div className='feature_box' style={{ height }} >
                                        <Form key={key} className="feature_form" style={{ paddingBottom: gap }}>

                                            <Form.Select
                                                className='reason_status selector'
                                                onChange={e => handleInputChange(key, 'reason_status', e.target.value)}
                                                value={formValues[key]?.reason_status || ''}
                                                placeholder='Select'
                                                disabled={changeLock}
                                            >
                                                <option>Select</option>
                                                <option value='Active use - no issue'>Active use - no issue</option>
                                                <option value='Active use - issue'>Active use - issue</option>
                                                <option value="Discovery / Experimental GC use">Discovery / Experimental GC use</option>
                                                <option value="Planned future deployment">Planned future deployment</option>
                                                <option value="Using competitor">Using competitor</option>
                                                <option value="Using GC + competitor">Using GC + competitor</option>
                                                <option value="Not using">Not using</option>
                                                <option value="Not entitled">Not entitled</option>
                                            </Form.Select>

                                            {formValues[key]?.reason_status === 'Active use - issue' && (
                                                <Form.Select
                                                    className='issues_selector selector'
                                                    onChange={e => handleInputChange(key, 'issues_selector', e.target.value)}
                                                    value={formValues[key]?.issues_selector || ''}
                                                    placeholder='Select'
                                                    disabled={changeLock}
                                                >
                                                    <option>Select issue</option>
                                                    <option value='Feature limitations'>Feature limitations</option>
                                                    <option value='3rd party or integration issues'>3rd party or integration issues</option>
                                                    <option value='Performance / reliability issues'>Performance / reliability issues</option>
                                                    <option value='User experience'>User experience</option>
                                                    <option value='Customer constraints'>Customer constraints</option>
                                                    <option value='Misaligned customer expectations'>Misaligned customer expectations</option>
                                                    <option value='Security and compliance concerns'>Security and compliance concerns</option>
                                                    <option value='Change in business strategy'>Change in business strategy</option>
                                                    <option value='Services / partner execution Issues'>Services / partner execution Issues</option>
                                                    <option value='Other (provide detail)'>Other (provide detail)</option>
                                                </Form.Select>
                                            )}

                                            {(formValues[key]?.reason_status === 'Planned future deployment' || formValues[key]?.reason_status === 'Discovery / Experimental GC use')
                                                 && (
                                                <Form.Control
                                                    type="month"
                                                    className='date_selector selector '
                                                    onChange={e => handleInputChange(key, 'date_selector', e.target.value)}
                                                    value={formValues[key]?.date_selector || ''}
                                                    disabled={changeLock}
                                                />
                                            )}

                                            {(formValues[key]?.reason_status === 'Using competitor' || formValues[key]?.reason_status === 'Using GC + competitor') && (
                                                <Form.Select
                                                    className='competitor_selector selector'
                                                    onChange={e => handleInputChange(key, 'competitor_selector', e.target.value)}
                                                    value={formValues[key]?.competitor_selector || ''}
                                                    placeholder='Select'
                                                    disabled={changeLock}
                                                >
                                                    <option>Select competitor</option>
                                                    <option value='Unknown'>Unknown</option>
                                                    <option value='Other (provide detail)'>Other (provide detail)</option>
                                                    <option value='Homegrown solution'>Homegrown solution</option>
                                                    <option value='Active AI'>Active AI</option>
                                                    <option value='Adobe'>Adobe</option>
                                                    <option value='Afiniti'>Afiniti</option>
                                                    <option value='Airship'>Airship</option>
                                                    <option value='AlloMedia'>AlloMedia</option>
                                                    <option value='Altius'>Altius</option>
                                                    <option value='Amazon'>Amazon</option>
                                                    <option value='Amelia.ai'>Amelia.ai</option>
                                                    <option value='AmiVoice'>AmiVoice</option>
                                                    <option value='Animo'>Animo</option>
                                                    <option value='Apple'>Apple</option>
                                                    <option value='Atip'>Atip</option>
                                                    <option value='Avaya'>Avaya</option>
                                                    <option value='Balto'>Balto</option>
                                                    <option value='BazaarVoice'>BazaarVoice</option>
                                                    <option value='Boost AI'>Boost AI</option>
                                                    <option value='Brandwatch'>Brandwatch</option>
                                                    <option value='BT'>BT</option>
                                                    <option value='Calabrio'>Calabrio</option>
                                                    <option value='CallMiner'>CallMiner</option>
                                                    <option value='Capgemini (Odigo)'>Capgemini (Odigo)</option>
                                                    <option value='Cisco Broadsoft'>Cisco Broadsoft</option>
                                                    <option value='CleverTar'>CleverTar</option>
                                                    <option value='Cognigy'>Cognigy</option>
                                                    <option value='ComAround'>ComAround</option>
                                                    <option value='Comm100'>Comm100</option>
                                                    <option value='Concentrix'>Concentrix</option>
                                                    <option value='Confluence'>Confluence</option>
                                                    <option value='Contact Engine'>Contact Engine</option>
                                                    <option value='Convai'>Convai</option>
                                                    <option value='CoreAI'>CoreAI</option>
                                                    <option value='Coveo'>Coveo</option>
                                                    <option value='CreativeVirtual'>CreativeVirtual</option>
                                                    <option value='Cresta'>Cresta</option>
                                                    <option value='Deepgram'>Deepgram</option>
                                                    <option value='DialPad'>DialPad</option>
                                                    <option value='Drips'>Drips</option>
                                                    <option value='Egain'>Egain</option>
                                                    <option value='EHRConnect'>EHRConnect</option>
                                                    <option value='Email Ninja'>Email Ninja</option>
                                                    <option value='Email Wizard'>Email Wizard</option>
                                                    <option value='Embratel'>Embratel</option>
                                                    <option value='Epic'>Epic</option>
                                                    <option value='Fabric'>Fabric</option>
                                                    <option value='Falcon'>Falcon</option>
                                                    <option value='Fale Sempre'>Fale Sempre</option>
                                                    <option value='First Help'>First Help</option>
                                                    <option value='FoneDynamics'>FoneDynamics</option>
                                                    <option value='Foresight Voice Mining'>Foresight Voice Mining</option>
                                                    <option value='Freshworks'>Freshworks</option>
                                                    <option value='Glia'>Glia</option>
                                                    <option value='Gnani.ai'>Gnani.ai</option>
                                                    <option value='Google'>Google</option>
                                                    <option value='Gyant'>Gyant</option>
                                                    <option value='HelpJuice'>HelpJuice</option>
                                                    <option value='Hexaware'>Hexaware</option>
                                                    <option value='HMCom'>HMCom</option>
                                                    <option value='Hootsuite'>Hootsuite</option>
                                                    <option value='Hyro'>Hyro</option>
                                                    <option value='IBM'>IBM</option>
                                                    <option value='Infobip'>Infobip</option>
                                                    <option value='Insite'>Insite</option>
                                                    <option value='Intelepeer'>Intelepeer</option>
                                                    <option value='Intercom'>Intercom</option>
                                                    <option value='Kaos'>Kaos</option>
                                                    <option value='Karaden Push'>Karaden Push</option>
                                                    <option value='Karakuri'>Karakuri</option>
                                                    <option value='Karte'>Karte</option>
                                                    <option value='KERV'>KERV</option>
                                                    <option value='Konnect Insights'>Konnect Insights</option>
                                                    <option value='Kore AI'>Kore AI</option>
                                                    <option value='LINE'>LINE</option>
                                                    <option value='LinkedIn'>LinkedIn</option>
                                                    <option value='Live Chat'>Live Chat</option>
                                                    <option value='LivePerson'>LivePerson</option>
                                                    <option value='LivePro'>LivePro</option>
                                                    <option value='Lumen'>Lumen</option>
                                                    <option value='Media4U'>Media4U</option>
                                                    <option value='Meta'>Meta</option>
                                                    <option value='Microsoft'>Microsoft</option>
                                                    <option value='Mobilus'>Mobilus</option>
                                                    <option value='Mosaicx'>Mosaicx</option>
                                                    <option value='MTalk'>MTalk</option>
                                                    <option value='NICE'>NICE</option>
                                                    <option value='Noble Systems'>Noble Systems</option>
                                                    <option value='Nuance'>Nuance</option>
                                                    <option value='Observe AI'>Observe AI</option>
                                                    <option value='Omilia'>Omilia</option>
                                                    <option value='OpenAI'>OpenAI</option>
                                                    <option value='Open Messaging'>Open Messaging</option>
                                                    <option value='OpenText'>OpenText</option>
                                                    <option value='Optus'>Optus</option>
                                                    <option value='Oracle'>Oracle</option>
                                                    <option value='Orange SMS'>Orange SMS</option>
                                                    <option value='Panviva'>Panviva</option>
                                                    <option value='Parlence'>Parlence</option>
                                                    <option value='Parloa'>Parloa</option>
                                                    <option value='Pega'>Pega</option>
                                                    <option value='PKSHA'>PKSHA</option>
                                                    <option value='ProcedureFlow'>ProcedureFlow</option>
                                                    <option value='ProKeep'>ProKeep</option>
                                                    <option value='Qualtrics'>Qualtrics</option>
                                                    <option value='Quick AI'>Quick AI</option>
                                                    <option value='Quiq'>Quiq</option>
                                                    <option value='Reddit'>Reddit</option>
                                                    <option value='Replicant AI'>Replicant AI</option>
                                                    <option value='Retarus'>Retarus</option>
                                                    <option value='RightConnect'>RightConnect</option>
                                                    <option value='RingCentral'>RingCentral</option>
                                                    <option value='Riptide'>Riptide</option>
                                                    <option value='Salesforce'>Salesforce</option>
                                                    <option value='Salesloft'>Salesloft</option>
                                                    <option value='ServiceCentral'>ServiceCentral</option>
                                                    <option value='ServiceNow'>ServiceNow</option>
                                                    <option value='Shelf'>Shelf</option>
                                                    <option value='Sinch'>Sinch</option>
                                                    <option value='Speech Miner'>Speech Miner</option>
                                                    <option value='SPITCH'>SPITCH</option>
                                                    <option value='Sprinklr'>Sprinklr</option>
                                                    <option value='Sprout'>Sprout</option>
                                                    <option value='Squiz'>Squiz</option>
                                                    <option value='Success KPI'>Success KPI</option>
                                                    <option value='Syniverse'>Syniverse</option>
                                                    <option value='Take Blip'>Take Blip</option>
                                                    <option value='Talk Desk'>Talk Desk</option>
                                                    <option value='TBC'>TBC</option>
                                                    <option value='TCL'>TCL</option>
                                                    <option value='Telecats'>Telecats</option>
                                                    <option value='Telefonica'>Telefonica</option>
                                                    <option value='TellVoice'>TellVoice</option>
                                                    <option value='Telstra'>Telstra</option>
                                                    <option value='TOMS'>TOMS</option>
                                                    <option value='ToolkitPlus'>ToolkitPlus</option>
                                                    <option value='Transmit'>Transmit</option>
                                                    <option value='Trax'>Trax</option>
                                                    <option value='Trumpia'>Trumpia</option>
                                                    <option value='Twilio'>Twilio</option>
                                                    <option value='USU'>USU</option>
                                                    <option value='Varant'>Varant</option>
                                                    <option value='Vee'>Vee</option>
                                                    <option value='Verint'>Verint</option>
                                                    <option value='Viadialog'>Viadialog</option>
                                                    <option value='Vibes'>Vibes</option>
                                                    <option value='Vivo'>Vivo</option>
                                                    <option value='Vonex'>Vonex</option>
                                                    <option value='Voxigene'>Voxigene</option>
                                                    <option value='WhatsApp'>WhatsApp</option>
                                                    <option value='Zaion'>Zaion</option>
                                                    <option value='Zendesk'>Zendesk</option>
                                                    <option value='Zenvia'>Zenvia</option>
                                                    <option value='Zoom'>Zoom</option>

                                                </Form.Select>
                                            )}

                                            {formValues[key]?.reason_status === 'Not using' && (
                                                <Form.Select
                                                    className='reason_selector selector'
                                                    onChange={e => handleInputChange(key, 'reason_selector', e.target.value)}
                                                    value={formValues[key]?.reason_selector || ''}
                                                    placeholder='Select'
                                                    disabled={changeLock}
                                                >
                                                    <option>Select Reason</option>
                                                    <option value='Not aligned to customer strategy'>Not aligned to customer strategy</option>
                                                    <option value='Value perception issues'>Value perception issues</option>
                                                    <option value='Partner Strategic Conflict'>Partner Strategic Conflict</option>
                                                    <option value='Not aligned to market/country requirements'>Not aligned to market/country requirements</option>
                                                    <option value='Technical & functional limitations'>Technical & functional limitations</option>
                                                    <option value='Usability challenges'>Usability challenges</option>
                                                    <option value='Customer constraints'>Customer constraints</option>
                                                    <option value='Customer knowledge gaps'>Customer knowledge gaps</option>
                                                    <option value='Compliance limitation'>Compliance limitation</option>
                                                    <option value='Dont know'>Don’t know</option>
                                                    <option value='Other (provide detail)'>Other (provide detail)</option>
                                                </Form.Select>
                                            )}
                                            <Form.Control
                                                className='comments_text_box'
                                                as="textarea"
                                                value={formValues[key]?.comments_text_box || ''}
                                                onChange={e => handleInputChange(key, 'comments_text_box', e.target.value)}
                                                rows={4}
                                                disabled={changeLock}
                                            />


                                        </Form>
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
            {userCanEdit === true ?
                <div className="floating-button">
                    <Button variant={buttonVariant} disabled={sending} onClick={handleEditClick}>
                        {buttonText}
                    </Button>
                    <Button variant="success" disabled={sending} onClick={handleSubmit}>
                        {sending ? (
                            <>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                {' '}Saving...
                            </>
                        ) : (
                            'Save'
                        )}
                    </Button>
                </div>
                : ''}
        </div>
    );
};