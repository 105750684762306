import C360Logo from '../components/C360Logo'
import ab_dashboards from '../jsons/adoption_breadth_dashboards.json'

const enviroment = process.env.REACT_APP_DEPLOY_ENV;
const resource_materials_link = 'https://genesyslab.sharepoint.com/:f:/s/CSDataAnalytics/EicH_vQ4crtJjp0amXK9zo4B1gActObiz03upXjtuTeihQ?e=apEcyA'
let url = ab_dashboards[enviroment]['aggregate']
export default function Adoption_breadth_aggregate() {
  return (
    <>
      <div className="page_section">
      <div className='top-section mb-3'>
          <div className='filters'>
          <C360Logo/>

            <div className='select-box'>

            </div>

            <div style={{ marginLeft: 'auto', alignItems: 'center', display: 'flex', height: '100%', fontWeight: 'bold' }}>
              <a href={resource_materials_link} target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>
                  CSM Resource Materials
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" style={{ marginLeft: '2px', marginTop: '-3px' }}>
                  <path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3z" fill="white"/>
              </svg>
              </a>
            </div>
          </div>
        </div>
        <div className='dashboard_container center'>
          <div>
          <tableau-viz id="tableauViz"
            src={url} width="100%" toolbar="bottom" hide-tabs>
          </tableau-viz>
          </div>

        </div>
      </div>
    </>
  )
  }
