import axios from 'axios';
import { get_OktaToken, replaceCharactersCommentsTextBox, replaceSingleQuoteString} from '../scripts/helpers';
import endpoints from '../jsons/env_endpoints.json';
const enviroment = process.env.REACT_APP_DEPLOY_ENV;


export async function get_analysis(account_id, authState) {
    const oktaToken=get_OktaToken(authState)
    let url = endpoints[enviroment]['overall']
    let body = {
        user: "genesys_web_c360",
        account_id: account_id,
        typecode: 4,
        oktaToken: oktaToken
    }
    let response = await axios.post(url, body)
    return (response['data']);
}

export async function get_all_accounts(authState) {
    const oktaToken=get_OktaToken(authState)
    let url = endpoints[enviroment]['overall']
    let body = {
        user: "genesys_web_c360",
        account_id: '',
        typecode: 3,
        oktaToken: oktaToken
    }
    let response = await axios.post(url, body)
    return (response['data']);
}

export async function get_last_account(authState,user_email) {
    const oktaToken=get_OktaToken(authState)
    let url = endpoints[enviroment]['overall']
    let body = {
        user: "genesys_web_c360",
        account_id: "",
        typecode: 8,
        user_email:user_email,
        oktaToken: oktaToken
    }
    let response = await axios.post(url, body)
    return (response['data']);
}

export async function update_last_account(account_id, user_email,authState) {
    const oktaToken=get_OktaToken(authState)
    let url = endpoints[enviroment]['overall']
    let body = {
        user: "genesys_web_c360",
        account_id: account_id,
        typecode: 9,
        oktaToken: oktaToken,
        user_email:user_email
    }
    let response = await axios.post(url, body)
    return (response['data']);
}



export async function send_adoption_breadth_inputs(account_id, input_json, authState,user_email) {
    const oktaToken=get_OktaToken(authState)
    let url = endpoints[enviroment]['adoption_breadth']
    let body = {
        user: "genesys_web_c360",
        account_id: replaceSingleQuoteString(account_id),
        typecode: 2,
        input_json: replaceCharactersCommentsTextBox(input_json),
        user_email:user_email,
        oktaToken: oktaToken
    }
    let response = await axios.post(url, body)
    
    return (true);

}
export async function get_adoption_breadth_inputs(account_id, authState) {
    const oktaToken=get_OktaToken(authState)
    let url = endpoints[enviroment]['adoption_breadth']
    let body = {
        user: "genesys_web_c360",
        typecode: 1,
        account_id: account_id,
        oktaToken: oktaToken
    }
    let response = await axios.post(url, body)
    return (response['data']);
}

