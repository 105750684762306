import axios from 'axios';
import { get_OktaToken, parseJwt } from '../scripts/helpers';
import endpoints from '../jsons/env_endpoints.json';

export async function send_feedback(authState,feedbackType,comment,page) {
    const oktaToken=get_OktaToken(authState)
    const parsedToken=parseJwt(oktaToken)
    const userEmail=parsedToken['sub']
    const enviroment = process.env.REACT_APP_DEPLOY_ENV;

    
    let url = endpoints[enviroment]['overall']
    let body = {
        user: "genesys_web_c360",
        account_id: 'account_id',
        typecode: 6,
        oktaToken: oktaToken,
        feedbackType:feedbackType,
        comment:comment,
        page:page,
        userEmail:userEmail,
    }
    let response = await axios.post(url, body)
    if (response['status']==200){
        return true
    }
    return false;
}