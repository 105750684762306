export function get_OktaToken(authState) {
    if (authState?.isAuthenticated) {
        return authState.accessToken.accessToken
    }
    else
        return ""
}

export function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

// Replaces ' and " for `, \ for \\\\ and \n for \\n
export function replaceCharactersCommentsTextBox(formValues) {
    let newFormValues = structuredClone(formValues)
        for (const key in newFormValues) {
            if (newFormValues.hasOwnProperty(key)) {
              const value = newFormValues[key]
              if (typeof value === 'string') {
                newFormValues[key] = value.replace(/['"]/g, '`').replace(/\\/g, '\\\\').replace(/\n/g, '\\n').replace(/\r/g, '\\r')
              }
              if (value.comments_text_box) {
                newFormValues[key].comments_text_box = value.comments_text_box.replace(/['"]/g, '`').replace(/\\/g, '\\\\').replace(/\n/g, '\\n').replace(/\r/g, '\\r')
            }
            }
          }
    return newFormValues
}

export function replaceSingleQuoteString(string) {
    return string.replace("'", "''")
}
